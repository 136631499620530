<template>
  <div  class="breadcrumb-item"  style="margin-right: 10px;">

      <div class='nav-link'
           :class="{ 'chosen': containerID === id }"
           @click="setInputMode"
           v-show="!inputMode"
      >
        {{name}}
      </div>
      <div class='nav-link tab-input'
           :class="{ 'chosen': containerID === id }"
           v-show="inputMode"
      >
        <v-text-field
          style="padding-top: 0px;"
          label="Create Location"
          v-model="newName"
          class="pa-3"
          :hide-details="true"
        ></v-text-field>
        <div class="input-right" @click.stop="addNewContainer" v-show="inputMode">
          <Icon
            iconName="check-circle"
            size="8px"
            color="green"
          />
        </div>
        <div class="top-right no-hov-show" @click.stop="setInputMode" v-show="inputMode" >
          <Icon
            iconName="times"
            size="4px"
            color="grey"
          />
        </div>
      </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Icon from '@/components/elements/Icon'
import TreeModel from 'tree-model-improved'
import { cloneDeep } from 'lodash'
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

const uuidv4 = require('uuid').v4

export default {
  name: 'NewParent',
  components: { Icon },
  props: {
    name: String,
    id: String,
    setActiveParent: Function,
    containerID: String,
    toggleEditMode: Function
  },
  data () {
    return {
      containerNode: {},
      inputMode: false,
      tree: [],
      newName: ''
    }
  },
  computed: {
    ...mapGetters({
      getContainers: 'containers/getContainers',
      getMyIds: 'login/getMyIds'
    })

  },
  mounted () {
    if (typeof this.getContainers === 'object') {
      this.tree = treeModel.parse(cloneDeep(this.getContainers))
      this.containerNode = this.tree.first(node => {
        return node.model.id === this.getContainers.id // grab top level container id
      })
    }
  },
  methods: {
    ...mapActions({
      updateContainer: 'containers/updateContainer'
    }),

    editContainer () {
      console.log('here we go')
    },
    setInputMode () {
      this.inputMode = !this.inputMode
    },
    updateStateContainer (newParent) {
      this.updateContainer({ container: newParent, msgType: 'container_create', updatedObj: newParent })
      this.editMode = false
    },
    addNewContainer () {
      let newChilds = cloneDeep(this.tree.model)
      newChilds.id = uuidv4()
      delete newChilds.optimisticLock
      let newParent = {
        id: this.tree.model.id,
        customerId: this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT',
        fulfillerId: this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT',
        name: this.newName,
        devices: [],
        children: [newChilds],
        optimisticLock: this.tree.model.optimisticLock,
        metadata: {
          name: '',
          status: '',
          text: ''
        }
      }
      this.updateStateContainer(newParent) // send container to update in state & local
      setTimeout(() => {
        this.inputMode = !this.inputMode
        this.toggleEditMode()
        this.$router.push({ name: 'Containers' })
      }, 600)
    }
  }
}
</script>
